import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://gentle-journey-80973-ad54130c0f36.herokuapp.com/api/parts';

export const getParts = async () => {
    const response = await axios.get(`${API_URL}`);
    return response.data;
};

export const getPartById = async (id) => {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
};

export const getPartBySlug = async (slug) => {
    const response = await axios.get(`${API_URL}/slug/${slug}`);
    return response.data;
};

export default {
    getParts,
    getPartById,
    getPartBySlug
};
