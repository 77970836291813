import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { getParts } from '../api/Parts';
import { Link } from 'react-router-dom';

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
`;

const HeaderRow = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr;
    padding: 10px;
    background-color: #2A292F;
    color: #E4E1E9;
    border-bottom: 2px solid #ddd;
`;

const HeaderItem = styled.button`
    background: none;
    border: none;
    font-weight: bold;
    text-align: left;
    color: #E4E1E9;
    cursor: pointer;
    &:hover {
        color: #4CAF50;
    }
`;

const PartList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
`;

const fadeOut = css`
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
`;

const PartItem = styled.li`
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    ${(props) => props.isFading && fadeOut}
`;

const PartImage = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
`;

const PartInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const PartTitle = styled(Link)`
    font-size: 16px;
    color: #333;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

const PartCategory = styled.p`
    margin: 5px 0;
    font-size: 14px;
    color: #666;
`;

const PartRating = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const Star = styled.span`
    color: #FFD700;
    margin-right: 2px;
`;

const PartPrice = styled.span`
    font-size: 16px;
    color: #888;
    text-align: right;
`;

const AddButton = styled.button`
    background-color: #4CAF50;
    color: white;
    padding: 6px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #45a049;
    }
`;

const SortOptions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
`;

const SortButton = styled.button`
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #2A292F;
    color: #E4E1E9;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: #4CAF50;
        color: white;
    }
`;

const FilterOptions = styled.div`
    margin-bottom: 20px;
    text-align: center;
`;

const FilterButton = styled.button`
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #2A292F;
    color: #E4E1E9;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: #4CAF50;
        color: white;
    }
`;

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const PageButton = styled.button`
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #2A292F;
    color: #E4E1E9;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: #4CAF50;
        color: white;
    }
`;

const Parts = ({ onAddItem }) => {
    const [parts, setParts] = useState([]);
    const [sortKey, setSortKey] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [filterCategory, setFilterCategory] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [partsPerPage] = useState(5);
    const [isFading, setIsFading] = useState(false);

    useEffect(() => {
        const fetchParts = async () => {
            const data = await getParts();
            setParts(data);
        };
        fetchParts();
    }, []);

    const handleFilter = (category) => {
        setIsFading(true);
        setTimeout(() => {
            setFilterCategory(category);
            setCurrentPage(1);
            setIsFading(false);
        }, 500);
    };

    const sortParts = (key) => {
        const sortedParts = [...parts].sort((a, b) => {
            if (a[key] < b[key]) return sortOrder === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        setParts([]);
        setTimeout(() => {
            setParts(sortedParts);
        }, 0);
        setSortKey(key);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const filteredParts = filterCategory
        ? parts.filter(part => part.category === filterCategory)
        : parts;

    const indexOfLastPart = currentPage * partsPerPage;
    const indexOfFirstPart = indexOfLastPart - partsPerPage;
    const currentParts = filteredParts.slice(indexOfFirstPart, indexOfLastPart);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <Container>
            <FilterOptions>
                <FilterButton onClick={() => handleFilter('')}>All</FilterButton>
                <FilterButton onClick={() => handleFilter('Furniture')}>Furniture</FilterButton>
                <FilterButton onClick={() => handleFilter('Storage')}>Storage</FilterButton>
                <FilterButton onClick={() => handleFilter('Lighting')}>Lighting</FilterButton>
                <FilterButton onClick={() => handleFilter('Ambiance')}>Ambiance</FilterButton>
                <FilterButton onClick={() => handleFilter('Technology')}>Technology</FilterButton>
            </FilterOptions>
            <HeaderRow>
                <HeaderItem onClick={() => sortParts('name')}>Name</HeaderItem>
                <HeaderItem onClick={() => sortParts('category')}>Category</HeaderItem>
                <HeaderItem onClick={() => sortParts('rating')}>Rating</HeaderItem>
                <HeaderItem onClick={() => sortParts('price')}>Price</HeaderItem>
            </HeaderRow>
            <PartList>
                {currentParts.map((part) => (
                    <PartItem key={part.slug} isFading={isFading}>
                        <PartImage src={part.imageUrl} alt={part.name} />
                        <PartInfo>
                            <PartTitle to={`/part/${part.slug}`}>{part.name}</PartTitle>
                            <PartCategory>{part.category}</PartCategory>
                        </PartInfo>
                        <PartRating>
                            {[...Array(5)].map((_, index) => (
                                <Star key={index}>{index < part.rating ? '★' : '☆'}</Star>
                            ))}
                        </PartRating>
                        <PartPrice>${part.price}</PartPrice>
                        <AddButton onClick={() => onAddItem(part)}>Add</AddButton>
                    </PartItem>
                ))}
            </PartList>
            <Pagination>
                {[...Array(Math.ceil(filteredParts.length / partsPerPage)).keys()].map(number => (
                    <PageButton key={number + 1} onClick={() => paginate(number + 1)}>
                        {number + 1}
                    </PageButton>
                ))}
            </Pagination>
        </Container>
    );
};

export default Parts;
