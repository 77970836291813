import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Community from './components/Community';
import Login from './components/Login';
import Register from './components/Register';
import Setup from './components/Setup';
import Parts from './components/Parts';
import PartDetails from './components/PartDetails';
import Inspiration from './components/Inspiration';
import SubmitInspiration from './components/SubmitInspiration';
import ProtectedRoute from './components/ProtectedRoute';
import './styles/styles.css';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);

    const handleLogin = (username, token) => {
        setIsLoggedIn(true);
        setUser(username);
        localStorage.setItem('authToken', token);
    };

    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<><Hero /><Community /></>} />
                <Route path="/login" element={<Login onLogin={handleLogin} />} />
                <Route path="/register" element={<Register />} />
                <Route path="/setup" element={<Setup />} />
                <Route path="/parts" element={<Parts />} />
                <Route path="/part/:id" element={<PartDetails />} />
                <Route path="/inspiration" element={<Inspiration />} />
                <Route path="/updates" element={<Community />} />
                <Route path="/submit-inspiration" element={<ProtectedRoute element={<SubmitInspiration />} isLoggedIn={isLoggedIn} />} />
            </Routes>
        </Router>
    );
};

export default App;
