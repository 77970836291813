import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        console.log('Submitting registration...');
        try {
            const response = await axios.post('https://gentle-journey-80973-ad54130c0f36.herokuapp.com/register', { username, email, password });
            console.log('Response:', response.data);
            navigate('/');  // Redirect to the main page upon successful registration
        } catch (error) {
            console.error('Error during registration:', error);
            if (error.response && error.response.status === 400) {
                setError('Email already exists');  // Show duplicate email error message
            } else {
                setError('Registration failed. Please try again.');  // Show generic error message
            }
        }
    };

    return (
        <div className="form-container">
            <h2>Register</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleRegister}>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    required
                />
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                />
                <button type="submit">Register</button>
            </form>
        </div>
    );
};

export default Register;
