import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ user, onLogout }) => {
    return (
        <header>
            <div className="header-content">
                <div className="left-section">
                    <div className="logo">
                        <img src="/logo.png" alt="Logo" />
                        <Link to="/" className="company-name">EnhanceDesk</Link>
                    </div>
                    <nav className="navbar">
                        <ul>
                            <li><Link to="/setup">Setup</Link></li>
                            <li><Link to="/parts">Parts</Link></li>
                            <li><Link to="/inspiration">Inspiration</Link></li>
                            <li><Link to="/updates">Get Updates</Link></li>
                        </ul>
                    </nav>
                </div>
                <div className="right-section">
                    {user ? (
                        <div className="auth-buttons">
                            <span className="username">Hello, {user.username}</span>
                            <button className="button" onClick={onLogout}>Sign Out</button>
                        </div>
                    ) : (
                        <div className="auth-buttons">
                            <Link to="/login" className="button">Login</Link>
                            <Link to="/register" className="button">Register</Link>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
