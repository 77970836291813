import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const InspirationImage = styled.img`
  width: 80%;
  max-width: 800px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const PartsList = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
  margin-top: 20px;
  padding: 20px;
  background-color: #2A292F;
  border-radius: 10px;
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const PartItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #444;
  &:last-child {
    border-bottom: none;
  }
`;

const PartLink = styled.a`
  color: #C2C1FF;
  text-decoration: none;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const PartDetails = styled.div`
  flex: 1;
  margin-left: 20px;
`;

const PartTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #E4E1E9;
`;

const PartImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 20px;
`;

const Inspiration = () => {
  const [showParts, setShowParts] = useState(false);

  const handleImageClick = () => {
    setShowParts(true);
  };

  const parts = [
    {
      name: 'Samsung (S34J55x)',
      link: 'https://amzn.to/3X7w88y',
      imageUrl: 'https://m.media-amazon.com/images/I/71y4oBIWcdL._AC_SL1500_.jpg',
    },
    {
      name: 'Logitech G PRO X SUPERLIGHT',
      link: 'https://amzn.to/4dryXHv',
      imageUrl: 'https://m.media-amazon.com/images/I/519DPaSOa5L._AC_SL1500_.jpg',
    },
    {
      name: 'Havit KB496L',
      link: 'https://www.havit.hk/products/kb496l-backlit-mechanical-gaming-keyboard/',
      imageUrl: '', // No image available
    },
    {
      name: 'Steelseries ARCTIS 7',
      link: 'https://amzn.to/3WPLNbp',
      imageUrl: 'https://m.media-amazon.com/images/I/71exY5PBroL._AC_SL1500_.jpg',
    },
    {
      name: 'Creative Pebble',
      link: 'https://amzn.to/3WTb18U',
      imageUrl: 'https://m.media-amazon.com/images/I/61aza8PGr+L._AC_SL1500_.jpg',
    },
    {
      name: 'DonOne (40x90cm)',
      link: '',
      imageUrl: '', // No image available
    },
    {
      name: 'Richmond from Ilva',
      link: '',
      imageUrl: '', // No image available
    },
    {
      name: 'Autonomous Ergochair pro',
      link: '',
      imageUrl: '', // No image available
    },
    {
      name: 'Shelves, posters and storage boxes',
      link: '',
      imageUrl: '', // No image available
    },
  ];

  return (
    <div>
      {!showParts ? (
        <ImageWrapper>
          <InspirationImage
            src="/images/Image1.jpg"
            alt="Inspiration Office Setup"
            onClick={handleImageClick}
          />
        </ImageWrapper>
      ) : (
        <PartsList>
          {parts.map((part, index) => (
            <PartItem key={index}>
              {part.imageUrl && <PartImage src={part.imageUrl} alt={part.name} />}
              <PartDetails>
                <PartTitle>
                  <PartLink href={part.link} target="_blank" rel="noopener noreferrer">
                    {part.name}
                  </PartLink>
                </PartTitle>
              </PartDetails>
            </PartItem>
          ))}
        </PartsList>
      )}
    </div>
  );
};

export default Inspiration;
