import React from 'react';
import { useParams } from 'react-router-dom';
import { getPartBySlug } from '../api/Parts';
import styled from 'styled-components';

const PartDetailsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #E4E1E9;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #FFD700; /* Gold for stars */
`;

const ReviewCount = styled.span`
  color: #FFD700;
  margin-left: 10px;
  font-size: 14px;
`;

const PartImage = styled.img`
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
`;

const InfoTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
`;

const AmazonButton = styled.a`
  background-color: #FF9900;
  color: white;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    background-color: #e68a00;
  }
`;

const Specifications = styled.div`
  margin-top: 20px;
`;

const PartDetails = () => {
  const { slug } = useParams();
  const part = getPartBySlug(slug);

  if (!part) {
    return <PartDetailsContainer>Part not found</PartDetailsContainer>;
  }

  return (
    <PartDetailsContainer>
      <Title>{part.name}</Title>
      <Rating>
        {Array.from({ length: part.rating }).map((_, i) => (
          <span key={i}>★</span>
        ))}
        <ReviewCount>({part.reviewCount})</ReviewCount>
      </Rating>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <PartImage src={part.imageUrl} alt={part.name} />
        <InfoTable>
          <TableRow>
            <span>Merchant:</span>
            <span>{part.merchant}</span>
          </TableRow>
          <TableRow>
            <span>Price:</span>
            <span>${part.price}</span>
          </TableRow>
          <TableRow>
            <AmazonButton href={part.amazonLink} target="_blank">
              Buy on Amazon
            </AmazonButton>
          </TableRow>
        </InfoTable>
      </div>
      <Specifications>
        <h2>Specifications:</h2>
        <p>{part.specifications}</p>
      </Specifications>
    </PartDetailsContainer>
  );
};

export default PartDetails;
