// src/components/Hero.js
import React from 'react';

const Hero = () => {
    return (
        <div className="hero">
            <div className="hero-content">
                <p className="coming-soon">Coming soon!</p>
                <h1>EnhanceDesk</h1>
                <p className="hero-description">
                    We offer inspiration, item choices, and a community for those looking to upgrade their own space.
                </p>
                <button className="cta-button">Pick Your Space</button>
            </div>
            <div className="hero-image">
                <img src="/desk-setup.jpg" alt="Desk Setup" />
            </div>
        </div>
    );
};

export default Hero;
