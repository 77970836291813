import React, { useState } from 'react';
import './Community.css';

const Community = () => {
    const [email, setEmail] = useState('');

    const handleSubscribe = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://gentle-journey-80973-ad54130c0f36.herokuapp.com/api/subscribe/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error('Error subscribing:', error);
        }
    };

    return (
        <section className="community">
            <h2>Join Our Community</h2>
            <p>Stay ahead with the latest items and office setups</p>
            <form id="signup-form" onSubmit={handleSubscribe}>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">Sign Up</button>
            </form>
        </section>
    );
};

export default Community;
