// src/components/SubmitInspiration.js
import React, { useState } from 'react';
import axios from 'axios';

const SubmitInspiration = () => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        imageUrl: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/inspiration', formData);
            console.log('Inspiration submitted:', response.data);
            // Add success handling (e.g., redirect or show a success message)
        } catch (error) {
            console.error('Error submitting inspiration:', error);
            // Add error handling
        }
    };

    return (
        <div>
            <h2>Submit Your Inspiration</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Title:</label>
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Description:</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Image URL:</label>
                    <input
                        type="text"
                        name="imageUrl"
                        value={formData.imageUrl}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default SubmitInspiration;
