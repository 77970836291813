// src/components/Setup.js
import React, { useState } from 'react';

const Setup = () => {
    const [selectedItems, setSelectedItems] = useState([]);

    const items = ['Desk', 'Chair', 'Microphone', 'Headset', 'Other Necessities'];

    const handleSelectItem = (item) => {
        setSelectedItems([...selectedItems, item]);
    };

    return (
        <div className="setup-container">
            <h2>Choose Your Parts</h2>
            <ul>
                {items.map((item, index) => (
                    <li key={index} onClick={() => handleSelectItem(item)}>
                        {item}
                    </li>
                ))}
            </ul>
            <h3>Selected Items:</h3>
            <ul>
                {selectedItems.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        </div>
    );
};

export default Setup;
